import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { getPaymentIntent } from '../../../services/api';
import Modal from "react-modal";
import React, { useState } from "react";
import { useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import Retakecheckout from "../Retakecheckout";

const Aside = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  
  const activeTopicRef = useRef(null);
  const [quizDone, setQuizDone] = useState(false);
  const navigate= useNavigate();
const [clientSecret, setClientSecret] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloader , setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const quizStatus = sessionStorage.getItem('quizDone') === 'true';
    setQuizDone(quizStatus);
  }, []);

  useEffect(() => {
    let lastTopicFound = false;
  
    for (const section of props.sections) {
      const topics = section.topics;
      
      // Count topics with status = 2 and status = 1
      const status2Count = topics.filter(topic => topic.status === "2").length;
      const status1Count = topics.filter(topic => topic.status === "1").length;

      console.log('topics', section);
  
      // Check condition: all topics have status 2 except one with status 1
      if (status1Count === 1 && status2Count === topics.length - 1) {
        lastTopicFound = true;
        break;
      }
    }
  
    // Store the result in sessionStorage
    if (lastTopicFound) {
      sessionStorage.setItem("lastTopic", "1");
    } else {
      sessionStorage.setItem("lastTopic", "0");
    }
  }, [props.sections]);
  

  useEffect(()=>{
    if (activeTopicRef.current) {
      activeTopicRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  },[props.sections])

  useEffect(()=>{
    let foundTopic = null;
    let foundSection = null;

    for (const section of props.sections) {
      foundTopic = section.topics.find(topic => topic.status === "1");
      if (foundTopic) {
        foundSection = section;
        break; 
      }
    }
    if (foundTopic) {
      sessionStorage.setItem("currentTopic", JSON.stringify(foundTopic.topics));
      sessionStorage.setItem("currentTopicId", foundTopic.topics.id);
      sessionStorage.setItem("topicStatus", foundTopic.status);
      props.setTopic(foundTopic.topics);
      props.setPages(foundTopic.topics.pages);
      props.setCurrentSection(foundSection.sections);
      if (activeTopicRef.current) {
        activeTopicRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  },[]);

  const handleNext = async (e) => {
      setIsLoading(true);  // Set loading to true before the request
      e.preventDefault();
      const response = await getPaymentIntent();
      setClientSecret(response.data);
      setIsModalOpen(true);
      setIsLoading(false);  // Set loading to true before the request
  };
  const handleExam=()=>{
		sessionStorage.setItem("examAllow",true);
		navigate('/course/take-exam')
  }
  
  const setFinalshow = () => { 
    props.setFinalquizalert(true);
  }

  return (
    <>
      <aside class="sidebar bg-secondary-s p-4 rounded-2 mh-100 shadow-sm sticky-top top-20 overflow-x-hidden overflow-y-auto">
        <ul className="tasks">
          {props.sections.map((section, sectionindex) => {
            const isSectionOpen = section.topics.some(
              (topic) => topic.status === "1"
            );

            return (
              <li
                key={sectionindex}
                className={`position-relative ${
                  section.status === "2" ? "donesection" : "notDone"
                }`}
              >
                <div
                  className="accordion"
                  id={`accordionExample${section.sections.id}`}
                >
                  <div className="accordion-item bg-none border-0 rounded-2 mb-4">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button rounded-2 shadow-none ${
                          isSectionOpen ? "custom-open" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${section.sections.id}`}
                        aria-expanded={isSectionOpen ? "true" : "false"}
                        aria-controls={`collapse${section.sections.id}`}
                      >
                        <strong className="d-block">
                          {section.sections.title}
                        </strong>
                      </button>
                    </h2>
                    <div
                      id={`collapse${section.sections.id}`}
                      className={`accordion-collapse collapse ${
                        isSectionOpen ? "show" : ""
                      } px-0`}
                      data-bs-parent={`#accordionExample${section.sections.id}`}
                    >
                      <div className="accordion-body ps-3 pe-0 pt-2 pb-0">
                        {section.topics.map((topic, topicindex) => (
                          <><button
                          key={topicindex}
                          ref={topic.status === "1" ? activeTopicRef : null}
                          className={`btn btn-primary-topic  mt-4 ${
                            topic.time_spent === 0 ? "donetopic" : ""
                          } ${
                            topic.status === "1" ? "" : ""
                          } text-white w-100 text-start py-2 px-3 mt-3 last-btns fs-12`}
                            onClick={() => {
                              console.log(topic.status);
                            if(!props.sidebarlock){
                              props.setQuizalert(false);
                              props.setStartCourse(true);
                              props.setTopic(topic.topics);
                              sessionStorage.setItem("currentTopic",JSON.stringify(topic.topics));
                              sessionStorage.setItem("currentTopicId",topic.topics.id);
                              sessionStorage.setItem("topicStatus",topic.status);
                              sessionStorage.setItem("chapterID",topicindex+1);
                              props.setPages(topic.topics.pages);
                              props.setCurrentSection(section.sections);
                              if (
                                sectionindex === props.sections.length - 1 
                                &&
                                topicindex === section.topics.length - 1
                              ) {
                                sessionStorage.setItem("lastTopic", "1");
                              } else {
                                sessionStorage.setItem("lastTopic", "0");
                              }
                            }
                            
                          }}
                          disabled={topic.status === "0"}
                        >
                          {topic.time_spent === 0?(<i className=" icon fa fa-check-circle "></i>):(<i className="fa fa-angle-right"></i>)}
                         {" "}
                          Chap {topic.topics.position}: {topic.topics.title}
                         
                          <small className="fs-11 float-end mt-1">
                            ({topic.topics.pages.length} Pages)
                          </small>
                        </button>
                        <span className={`mt-1 ${topic.status === "2"?'active':'notactive'  } `}>{topic.status === "2"?(<><i class="fa fa-check" aria-hidden="true"></i></>):(<></>)} Chapter Quiz </span>
                        </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <span  onClick={() => {
                if (!quizDone) {
                  setFinalshow(); // Trigger only if quizDone is false
                }
            }}
          style={{ cursor: quizDone ? 'not-allowed' : 'pointer' }}
          className={`mt-1 rounded-2 py-4 ${quizDone ? 'active' : 'notactive'}`}>{quizDone == true ? (<><i class="fa fa-check" aria-hidden="true"></i></>) : (<></>)} <strong> Final Quiz </strong> </span>
        <span className="">
          {JSON.parse(sessionStorage.getItem('user')).retake_status === '1'?	<button className="call-to-action-btn border-0 mt-4 py-3" onClick={handleExam} style={{background: '#044a90'}}>Exam Retake</button>:<></>}
          {JSON.parse(sessionStorage.getItem('user')).retake_status === '2' && JSON.parse(sessionStorage.getItem('user')).retake_permission === '1' ?	<button onClick={handleExam} className="call-to-action-btn border-0 mt-4 py-3" style={{background: '#044a90'}}>Exam Retake</button>:<></>}
          {JSON.parse(sessionStorage.getItem('user')).retake_status === '2' && JSON.parse(sessionStorage.getItem('user')).retake_permission === '0' ?	<button  onClick={handleNext} className="call-to-action-btn border-0 mt-4 py-3" style={{background: '#e38d26'}}>{isLoading ? (
							<span>Loading...</span>  // Show loading text or a spinner
						) : (
							'Purchase Retake'  // Show the button text when not loading
						)}
						</button>:<></>}
        </span>
        <Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				className="modal-class"
				overlayClassName="overlay-class"
				ariaHideApp={false}
			>
				<div className="modal-header">
				<h2>Complete Your Payment</h2>
				<button onClick={() => setIsModalOpen(false)} className="close-btn">
					&times;
				</button>
				</div>
				<div className="modal-body">
				{clientSecret ? (
					<Elements stripe={stripePromise} options={{ clientSecret }}>
					<Retakecheckout
						clientSecret={clientSecret}
						setIsLoader={setIsLoader}
					/>
					</Elements>
				) : (
					<p>Loading payment details...</p>
				)}
				</div>
			</Modal>
        
      </aside>
    </>
  );
};

export default Aside;
