import React, { useEffect, useState , useRef} from "react";
import "../../style.css";
import Header from "./layout/Header";
import { fetchCourse, updateProfile } from "../../services/api";
import { courseDetail } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../images/loader.png";
import Dashboard from "./Dashboard";
import Securityquestion from "./Securityquestion";
import Scoreboard from "./Scoreboard";
import Exam from "./Exam";
import Recharge from "./Recharge";
import CourseResultCard from "./CourseResultCard";

const Course = (props) => {
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [course, setCourse] = useState();
  const [pages, setPages] = useState([]);
  const [topic, setTopic] = useState();
  const [topicstatus, setTopicStatus] = useState();
  const [mainloader, setMainLoader] = useState(false);
  const [currentSection, setCurrentSection] = useState();
  const [courseStatus,setCourseStatus] = useState();
  const [reload, setReload] = useState(false);
  const [startcourse, setStartCourse] = useState(false);
  const [progress, setProgress] = useState(0);
  const [username, setUserName] = useState(sessionStorage.getItem("user_name"));
  const [email, setEmail] = useState(sessionStorage.getItem("user_email"));
  const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem("token");
    if (!isAuthenticated) {
      navigate("/login");
    }
    getcourseprogress();
    getcourse();
  }, [navigate]);

  useEffect(() => {
    // Check if user data exists in sessionStorage and set it
    // const storedUsername = sessionStorage.getItem('user_name');
    // if (storedUsername) {
      // setEmail(sessionStorage.getItem("user").email);  // Set the username from sessionStorage
    // }
  }, []); 
  

  const getcourseprogress = async () => {
    setMainLoader(true);
    try {
      const token = sessionStorage.getItem("token");
      const user_id = sessionStorage.getItem("user_id");
      const response = await fetchCourse(token, user_id);
      setTopic(response.data.currentTopic);
      setPages(response.data.currentTopic.pages);
      setCurrentSection(response.data.currentTopic.section);
      setSections(response.data.courses);
      if(response.data.topicStatus){
        setTopicStatus(response.data.topicStatus.status);
      }
      setCourseStatus(response.data.courseStatus);
      setProgress(response.data.progress);
      sessionStorage.setItem("testResult",response.data.percent);
      sessionStorage.setItem("total",response.data.score);

      
      if(!response.data.questionStatus)
      {
        navigate("/course/security-question");
      }
      else{
        sessionStorage.setItem('securityCheck','1');
      }
    
    } catch (error) {
      console.error("Failed to fetch course progress", error);
    } finally {
      setMainLoader(false);
    }
  };
  const handleRetake =()=>{
    var myModalEl = document.getElementById('editProfileModal');
		var modal = window.bootstrap.Modal.getInstance(myModalEl)
		modal.hide();
		navigate("/course/retake-checkout");
	  }
  const getcourse = async () => {
    const token = sessionStorage.getItem("token");
    const response = await courseDetail(token);
    setCourse(response.data.title);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleExitExam=()=>{
    var myModalEl = document.getElementById('cancelexamModal');
		var modal = window.bootstrap.Modal.getInstance(myModalEl)
		modal.hide();
    setStartCourse(false);
    navigate("/course/home");
  }
  const handleNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleupdateprofile = async () => {
    const token = sessionStorage.getItem("token");
    const userId = sessionStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("user_name", username);
    formData.append("user_email", email);
    formData.append("user_id", userId);
    

    try {

      const response = await updateProfile(token, formData);
      console.log(response);

      // Check if the response is successful
      if (response.data) {
        sessionStorage.setItem('user',JSON.stringify(response.data.user));
        sessionStorage.setItem("user_email",response.data.user.email);
        sessionStorage.setItem("user_name",response.data.user.name);

        var myModalEl = document.getElementById('editProfileModal');
        var modal = window.bootstrap.Modal.getInstance(myModalEl)
        modal.hide();

        navigate('/course/home');
          setResponseMessage('Profile updated successfully!');
          setErrorMessage(''); // Clear any previous errors
      } else {
          setErrorMessage(response.error || 'An error occurred.');
          setResponseMessage('');
      }
    } catch (error) {
      console.log(error.response.data.errors);
        // Handle any network or unexpected errors
        setErrorMessage(error.response.data.errors);
        setResponseMessage('');
    }
    // if(response.data){
    //   sessionStorage.setItem('user',JSON.stringify(response.data.user));
    //   sessionStorage.setItem("user_email",response.data.user.email);
    //   sessionStorage.setItem("user_name",response.data.user.name);
    //   navigate('/course/home');
    // }
    // console.log(response);
    // navigate("/course/home");
  };

  const refreshSections = async () => {
    const token = sessionStorage.getItem("token");
    const user_id = sessionStorage.getItem("user_id");
    try {
      const response = await fetchCourse(token, user_id);
      setSections(response.data.courses);
      setProgress(response.data.progress);
    } catch (error) {
      console.error("Failed to refresh sections", error);
    }
  };

  const handleExit=async()=>{
     await getcourseprogress();
     setStartCourse(false);
    var myModalEl = document.getElementById('exampleModal');
    var modal = window.bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
  }

  return (
    <>
      {!topic ? (
        <div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
          <span class="position-absolute top-50 start-50 translate-middle">
            <img src={Loader} alt="Loader" />
          </span>
        </div>
      ) :(
        <>
          <Header />
          <main className="main">
            <div className="container position-relative z-1 pt-5 "> 
              {props.component == "dashboardhome" && (
                <Dashboard
                  topicstatus={topicstatus}
                  course={course}
                  sections={sections}
                  setPages={setPages}
                  pages={pages}
                  topic={topic}
                  setTopic={setTopic}
                  setReload={setReload}
                  refreshSections={refreshSections}
                  currentSection= {currentSection}
                  setCurrentSection = {setCurrentSection}
                  courseStatus = {courseStatus}
                  progress ={progress}
                  startcourse = {startcourse}
                  setStartCourse = {setStartCourse}
                />
              )}
               {props.component === "securityquestion" &&
                (
                  <Securityquestion/>
                )
                }
                 {props.component === "scoreboard" &&
                (
                  <Scoreboard/>
                )
                }
                 {props.component === "courseresultcard" &&
                (
                  <CourseResultCard startcourse = {startcourse}
                  setStartCourse = {setStartCourse} />
                )
                }
                {props.component === "exam" &&
                (
                  <Exam/>
                )
                }
                {props.component === "retake-checkout" &&
                (
                  <Recharge/>
                )
                }
            </div>
          </main>
          <div
            class="modal fade"
            id="editProfileModal"
            tabindex="-1"
            aria-labelledby="editProfileModalLabel"
            aria-hidden="true"
            ref={modalRef}
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="editProfileModalLabel">
                    Edit Profile
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setErrorMessage('')}
                    ></button>
                </div>
                <div class="modal-body p-4">
                  <form>
                    <h4 class="h4 mb-0">Contact Details</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-floating mt-4">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            placeholder={sessionStorage.getItem("user_name")}
                            onChange={handleNameChange}
                            value={username}
                          />
                          <label for="name">User Name</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating mt-4">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder={sessionStorage.getItem("user_email")}
                            onChange={handleEmailChange}
                            value={email}
                          />
                          <label for="email">Email Address</label>
                        </div>
                          <label className="text-danger">{errorMessage? errorMessage : ''}</label>
                      </div>
                      {/* {JSON.parse(sessionStorage.getItem('user')).retake_permission === '0'?
                          <div class="col-md-6 mt-4">
                          <label for="email">You have used your free retake</label>
                          <button
                          type="button"
                          class="btn btn-primary-s mt-3"
                          onClick={handleRetake}
                          >
                          <i class="fa fa-save me-1"></i>
                            Recharge Retake
                          </button>
                          </div>:<></>
                      } */}
                     
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary-s"
                    onClick={handleupdateprofile}
                  >
                    <i class="fa fa-save me-1"></i> Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="changePasswordModal"
            tabindex="-1"
            aria-labelledby="changePasswordModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="changePasswordModalLabel">
                    Change Password
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body p-4">
                  <form>
                    <h4 class="h4 pb-2">Change Your Password?</h4>
                    <p class="line-h-150">
                      Forgot your password or just want to change it? No
                      problem. We'll send you an email to reset your password
                      and enter a new one.
                    </p>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-floating mt-4">
                          <input
                            type="email"
                            class="form-control"
                            id="email2"
                            placeholder=" "
                          />
                          <label for="email2">Enter Your Email</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary-s">
                    <i class="fa fa-lock me-1"></i> Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="exampleModal" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  You will need to resume from the beginning of this Chapter if you Exit                
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" onClick={handleExit} class="btn btn-primary">Exit</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="cancelexamModal" ref={modalRef} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    If you choose to leave this exam you will be required to start the exam from the beginning.  Any questions you have completed will not be counted. <br></br>
                    <br/> Are you sure you want to Exit?             
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Continue Exam</button>
                  <button type="button" onClick={handleExitExam} class="btn btn-primary">Exit</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </>
  );
};
export default Course;
