import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  fetchPages,
  submitFinalquiz,
  fetchRemainingTime,
  getFinalquestions,
  getQuestion,
  updateTopic,
  updateTopicStatus,
} from "../../services/api";
import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const Content = (props) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [useranswer, setUseranswer] = useState("");
  const [currentquiztime, setCurrentquiztime] = useState();
  const [backremainingTime, setBackRemainingTime] = useState(0);
  const [lock, setLock] = useState(false);
  const [timerShow, setTimerShow] = useState(false);
  const currentCounterTimeRef = useRef(0);
  const [displayback , setDisplayback] = useState(props.courseStatus);
  const [showfinal, setShowfinal] = useState(false);
  const [showquestion, setShowquestion] = useState(false);
  const [quizerror, setQuizError] = useState(false);
  const [finalanswers, setFinalanswers] = useState({});
  const [finalquestions, setFinalquestions] = useState([]);
  const [startquiz, setStartQuiz] = useState(false);
  const [question, setQuestion] = useState();
  const [quizbutton, setQuizbutton] = useState(false);
  const [submitquizbutton, setSubmitquizButton] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  // const [hasStarted, setHasStarted] = useState(false);
  // const [hasReadAllPages, setHasReadAllPages] = useState(localStorage.getItem('hasReadAllPages') === 'true');
  const [isTextVisible, setIsTextVisible] = useState(false);

  
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Has Started:", hasStarted);
    const user = sessionStorage.getItem('user');
    if (user) {
     

      const courseStatus = JSON.parse(user).course_status;
      // console.log(courseStatus);
      if (courseStatus === '1') {
        setDisplayback(true);
      } else if (courseStatus === '2') {
        setLock(true);
      }
    }
    setCurrentPageIndex(0);
    setTimerShow(false); 
    if (props.topic && props.topic.id) {
      // console.log('props here', props.topic);
      getPages();
    }
  }, [props.topic]);

  const getPages = async () => {
    const token = sessionStorage.getItem("token");
    const user_id = sessionStorage.getItem("user_id");
    const id = props.topic.id;
    const response = await fetchPages(token, id, user_id);
    const timeInSeconds = convertTimeStringToSeconds(props.topic.time);

    setTotalTime(timeInSeconds);
    const timeSpent = response.data.progress.time_spent;
    
    
    if(response.data.progress.status === '2'){
        setTimerShow(false);
    }
    else{
      
      if(!lock && timeSpent != null && timeSpent === 0 && JSON.parse(sessionStorage.getItem('lastTopic') === '0')){
        props.setQuizalert(true);
      }
      // if(Number(timeSpent) === 0 && response.data.progress.status === '1'){
      //   props.setQuizalert(true);

      // }
      if (timeSpent && timeSpent > 0) {
        setTimerShow(true);
        setBackRemainingTime(timeSpent);
        return;
      } else if (timeSpent === 0) { 
        setTimerShow(false); 
        setBackRemainingTime(0);
        return;
         
      } else if (timeSpent === null) {
        setTimerShow(true);
        setBackRemainingTime(timeInSeconds);
        return;
      }
      

      // if(Number(timeSpent)== 0){
      
      // }
    }
  };

  let currentPage= {};
  if(currentPageIndex>0){
    currentPage = props.pages[currentPageIndex];
  }
  else{
    currentPage = props.pages[0];
  }


  // const markPagesAsRead = () => {
  //   setHasReadAllPages(true); // Mark pages as read and save to localStorage
  // };
  const handleButtonClick = () => {
    setIsTextVisible(!isTextVisible);
    props.setSidebarlock(false);
    
  };

  const  handlestart =()=>{
    // setHasStarted(true);
    const lastTopic = sessionStorage.getItem("lastTopic");
    const user = JSON.parse(sessionStorage.getItem('user'));  // Parse user data once for easier use
  
    if(user.test_status === '0' && user.retake_status !== '0'){
        navigate('/course/take-exam')
    }else{
      const currentPage = sessionStorage.getItem("currentPage");
      const topic = sessionStorage.getItem("currentTopic");
      if (lastTopic === '1') {
        setCurrentPageIndex(props.pages.length);
      } else if (currentPage) {
          setCurrentPageIndex(currentPage);
          props.setTopic(JSON.parse(topic));
      }else if(user.retake_status === '2'){
          navigate('/course/take-exam');
      }
      props.setStartCourse(true);
      props.setSidebarlock(false);
    }
    
  }

  const handleExit = ()=>{
    setDisplayback(true);
    setCurrentQuestionIndex(0);
    props.setStartCourse(false);
    props.setSidebarlock(false);

  }


  useEffect(() => {
    if(timerShow){
      const interval = setInterval(() => {
        updateRemainingTime();
        
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [timerShow]);

  // const updateStatusToDone = async () => {
  //   if (!props.topic || !props.topic.id) {
  //     console.error("Topic or Topic ID is undefined");
  //     return;
  //   }
  //   const token = sessionStorage.getItem("token");
  //   const user_id = sessionStorage.getItem("user_id");
  //   showQuestion(props.topic.id , user_id);

  //   // await updateTopic(token, props.topic.id, user_id);
  //   // props.setShowSuccess(true);
  //   // setTimerShow(false);
  //   // props.refreshSections();   
  // };

  const handleQuiz = ()=>{
    setQuizbutton(true);
    const user_id = sessionStorage.getItem("user_id");
    showQuestion(props.topic.id , user_id);
    setCurrentQuestionIndex(0);
    setStartQuiz(true);

  }

  const showQuestion = async (t_id,u_id)=>{
    setUseranswer("");
    setQuizError(false);
    const token = sessionStorage.getItem("token");
    if (sessionStorage.getItem('lastTopic') === '1') {
      const response = await getQuestion(token, t_id, u_id, '1');
      setFinalquestions(response.data);
      setShowfinal(true);

    } else {
      const response = await getQuestion(token, t_id, u_id, '0');
      setQuestion(response.data);
      setShowquestion(true);
    }
    setShowquestion(true);
    setTimerShow(false);
    setQuizbutton(false);
  } 

  const handleUseranswer=(e)=>{
      setUseranswer(e.target.value);
  }

  const handleUserFinalanswer = async (event, questionId) => {
    const selectedAnswer = event.target.value;
    setFinalanswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer, 
    }));
   
  };

  const updateRemainingTime = async () => {

    const token = sessionStorage.getItem("token");
    const user_id = sessionStorage.getItem("user_id");
    const topicId = sessionStorage.getItem("currentTopicId");

    await updateTopic(
      token,
      topicId,
      user_id,
      currentCounterTimeRef.current
    );
    props.refreshSections(); 

    // if(currentCounterTimeRef.current == 0){
    //   props.setShowSuccess(true);
    //   setTimerShow(false);
    //   props.refreshSections(); 
    // }
  };

  const convertTimeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const handleNext = () => {
    // const isLastPage = currentPageIndex + 1 === props.pages.length;
    
    // if (isLastPage) {
    //   setHasReadAllPages(true);
    //   localStorage.setItem('hasReadAllPages', 'true');
    // }
    setCurrentPageIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      sessionStorage.setItem("currentPage", newIndex);
      return newIndex;
    });
  };

  const submitfinalquiz=async()=>{
    props.setQuizalert(false);
    props.setFinalquizalert(false);
    sessionStorage.setItem("quizDone", 'true')
    if(Object.keys(finalanswers).length !== 3){
      setQuizError(true);
      return;
    }
    const token = sessionStorage.getItem("token");
    const user_id = sessionStorage.getItem("user_id");
    const formdata = new FormData();
    formdata.append('user_id', user_id);
    formdata.append('topic_id', props.topic.id);
    formdata.append('finalanswers',JSON.stringify(finalanswers));
    setFinalanswers({});
    const response = await submitFinalquiz(token, formdata);
    if(response){
      sessionStorage.removeItem("topicStatus");
      sessionStorage.removeItem("lastTopic");
      sessionStorage.setItem("user",JSON.stringify(response.data.user));
      sessionStorage.setItem("testResult",response.data.score);
      sessionStorage.setItem("total",response.data.total);
      setDisplayback(true);
      navigate("/course/scoreboard");
    }
  }

 const submitQuiz = async () => {
  props.setQuizalert(false);
  const token = sessionStorage.getItem("token");
  const user_id = sessionStorage.getItem("user_id");
  
  if (useranswer) {
    setSubmitquizButton(true);
    setQuizError(false);
    
    const response = await updateTopicStatus(token, props.topic.id, user_id, useranswer, question.id);
    if (response) {
      props.setShowSuccess(true);
      setTimerShow(false); // Ensure timer stops during processing
      setStartQuiz(false);
      setShowfinal(false);
      setShowquestion(false);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      
      // Fetch updated pages and reset timer state
      await getPages(); // Ensure it sets `timerShow` and `backremainingTime`
      
      props.refreshSections();  
      sessionStorage.setItem('topicStatus', '2');
      setQuestion("");
      setSubmitquizButton(false);

      // Determine the next topic to navigate to
      const currentSectionIndex = props.sections.findIndex(
        (section) => section.course_section_id === props.topic.course_section_id
      );

      if (currentSectionIndex >= 0) {
        const currentSection = props.sections[currentSectionIndex];
        const topicIndex = currentSection.topics.findIndex((topic) => topic.status === "1");
        let nextTopic = topicIndex >= 0 && topicIndex + 1 < currentSection.topics.length
          ? currentSection.topics[topicIndex + 1]
          : null;

        if (!nextTopic) {
          const nextSection = props.sections[currentSectionIndex + 1];
          if (nextSection) {
            nextTopic = nextSection.topics[0];
          }
        }

        if (nextTopic) {
          props.setTopic(nextTopic.topics);
          sessionStorage.setItem("currentTopic", JSON.stringify(nextTopic.topics));
          sessionStorage.setItem("currentTopicId", nextTopic.topics.id);
          sessionStorage.setItem("topicStatus", "1");
          props.setPages(nextTopic.topics.pages);

          setTimerShow(true); // Start the timer for the next topic
          setBackRemainingTime(convertTimeStringToSeconds(nextTopic.topics.time)); // Set initial time
          setCurrentPageIndex(0);  
        }
      }
    } else {
      setStartQuiz(false);
      setShowfinal(false);
      setShowquestion(false);
      getPages();
      setSubmitquizButton(false);
    }
  } else {
    setQuizError(true);
  }
};



  // const handleFinalQuiz = ()=>{
  //   const user_id = sessionStorage.getItem("user_id");
  //   showquestions(props.topic.id , user_id);
  //   setStartQuiz(true);
  // }

  // const showquestions=async(t_id, u_id)=>{
  //     const token = sessionStorage.getItem("token");
  //     const response = await getFinalquestions(token,t_id, u_id);
  //     setFinalquestions(response.data);
  // }

  const handlePrevious = () => {
    setCurrentPageIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      sessionStorage.setItem("currentPage", newIndex);
      return newIndex;
    });
  };

  const handlecompleteread=()=>{
    setDisplayback(true);
  }

  const readchapter =()=>{
    props.setStartCourse(true);
    setStartQuiz(false);
    setShowquestion(false);
    setShowfinal(false);
    props.setQuizalert(false);
    props.setSidebarlock(false);
    setFinalquestions([]);
    setQuestion("");
    setCurrentPageIndex(0);
    setSubmitquizButton(false);
  }

  const cancelFinalQuiz=()=>{
    setStartQuiz(false);
    setShowquestion(false);
    setShowfinal(false);
    setFinalquestions([]);
    setQuestion("");
    setDisplayback(true);
    setDisplayback(true);
    setCurrentQuestionIndex(0);
    props.setStartCourse(false);
    props.setSidebarlock(false);
    props.setFinalquizalert(false);
  }

  const cancelQuiz=()=>{
    setFinalanswers({});
    setStartQuiz(false);
    setShowquestion(false);
    setShowfinal(false);
    setCurrentQuestionIndex(0);
    props.setFinalquizalert(false);
    // props.setQuizalert(true);
    setFinalquestions([]);
    setQuestion("");
    setCurrentPageIndex(props.pages.length-1);
    setSubmitquizButton(false);
    }

  if(startquiz && showfinal){
    return(<div class="d-flex justify-content-center">
      <div className="final-screen text-align-center">
      {quizerror?(<><span className="text-danger alert-error">Select at least One Option</span><br></br><br></br></>):(<></>)} 
      {finalquestions.map((question, index) => (
          index === currentQuestionIndex && ( 
            <div key={index} className="mt-4">
              <span className="question">{question.question}</span>
              <br />
              <div className="d-flex mt-4 justify-content-around">
                {question.option_one && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_one}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="A"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_one}${question.id}`}
                    >
                      {question.option_one}
                    </label>
                  </>
                )}
                {question.option_two && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_two}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="B"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_two}${question.id}`}
                    >
                      {question.option_two}
                    </label>
                  </>
                )}
              </div>
              <div className="d-flex mt-4 justify-content-around">
                {question.option_three && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_three}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="C"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_three}${question.id}`}
                    >
                      {question.option_three}
                    </label>
                  </>
                )}
                {question.option_four && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_four}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="D"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_four}${question.id}`}
                    >
                      {question.option_four}
                    </label>
                  </>
                )}
              </div>
            </div>
          )
        ))}

        <div className="container mt-4 quiz-timer">
          <div className="row mt-3">
            <div className="col-lg-2 mt-4">
              <button onClick={cancelQuiz} className="btn btn-secondary-s mb-3 btn-lg w-90 text-white">
                <i className="fa fa-arrow-left fs-12"></i> Exit
              </button>
            </div>

            <div className="col-lg-8 col-md-6 mt-4">
              <div className="d-flex justify-content-between gap-4">
                {/* <button
                  className="btn btn-secondary-s text-white btn-lg w-100 rounded-2"
                  onClick={() => setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))}
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </button> */}
                <button
                  className="btn btn-secondary-s text-white btn-lg w-100 rounded-2"
                  onClick={() => setCurrentQuestionIndex((prev) => Math.min(prev + 1, finalquestions.length - 1))}
                  disabled={currentQuestionIndex === finalquestions.length - 1 || (currentQuestionIndex === Object.keys(finalanswers).length)}
                >
                  Next
                </button>
              </div>
            </div>

            <div className="col-2">
              <CountdownCircleTimer
                isPlaying
                duration={600}
                size={100}
                colors="#0BA8CF"
                onUpdate={(remainingTime) => {
                  setCurrentquiztime(remainingTime);
                  if (remainingTime === 0) {
                    cancelQuiz();
                  }
                }}
              >
                {() => {
                  const minutes = Math.floor(currentquiztime / 60);
                  const seconds = currentquiztime % 60;
                  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                }}
              </CountdownCircleTimer>
            </div>
          </div>
        </div>

        {currentQuestionIndex === finalquestions.length - 1 && (
          <div className="container mt-4">
            <button
              className="btn btn-primary-s text-white btn-lg w-100 rounded-2"
              onClick={submitfinalquiz}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );}
  
  if(startquiz && showquestion){
    return(<div class="d-flex justify-content-center">
              <div className="entry-screen text-align-center">
                 {quizerror?(<><span className="text-danger alert-error">Select at least One Option</span><br></br><br></br></>):(<></>)} 
                      <span class="question ">{question.question}</span>
                     <br></br>
                     <div className="d-flex mt-4 justify-content-around">
                     {question.option_one?(<> 
                      <input type="radio" className="d-none" id={question.option_one} name="topic_question" onChange={handleUseranswer} value="A"/>
                      <label className="customTab" for={question.option_one}>{question.option_one}</label></>):null}
                      {question.option_two?(<> 
                      <input type="radio" className="d-none" id={question.option_two} name="topic_question" onChange={handleUseranswer} value="B"/>
                      <label className="customTab" for={question.option_two}>{question.option_two}</label></>):null}
                      </div>
                      <div className=" mt-4 d-flex justify-content-around">
                      {question.option_three?(<> 
                     <input type="radio" className="d-none" id={question.option_three} name="topic_question" onChange={handleUseranswer} value="C"/>
                      <label className="customTab" for={question.option_three}>{question.option_three}</label></>):null}
                      {question.option_four?(<> 
                     <input type="radio" className="d-none" id={question.option_four} name="topic_question" onChange={handleUseranswer} value="D"/>
                      <label className="customTab" for={question.option_four}>{question.option_four}</label></>):null}
                     </div>
                   
                    
                                 
                     <div class="container mt-4 quiz-timer">
                        <div class="row mt-3">
                          <div class="col-lg-2 mt-4">
                          <button  onClick={cancelQuiz} class="btn btn-secondary-s mb-3 btn-lg w-90 text-white">
                            <i class="fa fa-arrow-left fs-12"></i> Exit
                          </button>
                          </div>
                          <div class="col-lg-8 col-md-6 mt-4">
                            <div class="d-flex justify-content-between gap-4">
                              {submitquizbutton?<span class="loader-test"></span>:<></>}
                              <button
                                disabled={submitquizbutton}
                                class="btn btn-primary-s text-white btn-lg w-100 rounded-2"
                                onClick={submitQuiz}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className="col-2">
                          <CountdownCircleTimer
                                isPlaying
                                isGrowing={true}
                                duration={180}
                                size={100}
                                initialRemainingTime = {180}
                                colors="#0BA8CF"
                                onUpdate={(remainingTime) => {
                                  setCurrentquiztime(remainingTime);
                                  if (remainingTime === 0) {
                                    cancelQuiz();
                                  }
                                }}
                              >
                                {({ remainingTime }) => {
                                    const minutes = Math.floor(currentquiztime / 60);
                                    const seconds = currentquiztime % 60;
                                    const formattedMinutes = String(minutes).padStart(2, '0');
                                    const formattedSeconds = String(seconds).padStart(2, '0');
                                    return `${formattedMinutes}:${formattedSeconds}`;
                                }}
                              </CountdownCircleTimer>
                          </div>
                        </div>
                      </div>
                      </div>  
              </div>
    );
  }
  if (props.finalQuizalert && props.startcourse) { 
    return (<>
      <div class="bg-white rounded-3 p-4 shadow mt-mb-20 mt-4 mb-5">
        <div class="d-flex justify-content-center">
          <div className="entry-screen text-align-center">
            <a href="javascript:;" class="logoText">
              <h3>Get Ready For the Final Quiz </h3>
                </a>
                <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i>Duration : 10 mins</strong>
                <br></br>
                <h2 class="">The final three questions will be presented and<span className="fw-bold"> may come from any section of the material.</span> </h2>
                <button name="btn" onClick={handleQuiz} class="btn btn-primary-s mb-3 mt-4 btn-lg w-100 text-white">Next</button>
                <button  onClick={cancelFinalQuiz} class="btn btn-secondary-s mb-3 btn-lg w-100 text-white">
                    Exit
                </button>
              </div>
            </div>
              </div>
            </>);
  }
  if(props.quizalert && props.startcourse){
    return(
      <>
      <div class="bg-white rounded-3 p-4 shadow mt-mb-20 mt-4 mb-5">
        <div class="d-flex justify-content-center">
          <div className="entry-screen text-align-center">
            <a href="javascript:;" class="logoText">
            <h3>{}You Completed the Chapter:
            </h3>
           
              </a>        
              <h2 className="alert-heading"><span> Chap {JSON.parse(sessionStorage.getItem("currentTopic")).position} “{JSON.parse(sessionStorage.getItem("currentTopic")).title}”</span> </h2>
              <h2 className="alert-para">{sessionStorage.getItem('lastTopic') === '1'?'but have not completed the final quiz  for this chapter.':'but have not completed the quiz question for this chapter.'}</h2>      
              <br></br>
              <div class="col-lg-12 col-md-12">
                <div class="d-flex justify-content-between mt-4 gap-4">
                  <button
                    class="btn btn-secondary-s text-white btn-md w-50 rounded-2"
                    onClick={readchapter}
                  >
                    <i class="fa fa-angle-left me-1"></i> Go to begining of chapter
                  </button>
                  <button
                    class="btn course-start btn-primary-s text-white btn-md w-50 rounded-2"
                    disabled={quizbutton}
                    onClick={handleQuiz}
                  >
                   Go to quiz <i class="fa fa-angle-right ms-1"></i>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
      </>
    );
  }
 
  if (props.pages.length === 0) {
    return (
      <div class="bg-white rounded-3 p-4 shadow mt-mb-20 mt-4 mb-5">
            <div class="title border-bottom pb-3">
              <h5 class="h5 fw-bold">{props.topic.title}</h5>
            </div>
            <div
              className="pageContent contentDisplay mt-4"
              dangerouslySetInnerHTML={{
                __html: `<div style="display: flex; justify-content: space-between; align-items: center;">
                  <h2 style="margin: 0;">Pages not uploaded</h2>
                 `
              }}
            />
      </div>
    );
  }
  
  return (
       <>
            {props.startcourse?(
              <>  
              <div class="bg-white rounded-3 p-4 shadow mt-mb-20 mt-4 mb-5">
                {timerShow === false && currentPageIndex === props.pages.length && sessionStorage.getItem('topicStatus') === '1'?(
                  <div class="d-flex justify-content-center">
                    <div className="entry-screen text-align-center">
                      {sessionStorage.getItem("lastTopic")=='1'?(<>
                        <a href="javascript:;" class="logoText">
                        <h3>Get Ready For the Final Quiz </h3>
                          </a>
                          <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i>Duration : 10 mins</strong>
                          <br></br>
                          <h2 class="">The final three questions will be presented and<span className="fw-bold"> may come from any section of the material.</span> </h2>
                          <button name="btn" onClick={handleQuiz} class="btn btn-primary-s mb-3 mt-4 btn-lg w-100 text-white">Next</button>
                          <button  onClick={cancelFinalQuiz} class="btn btn-secondary-s mb-3 btn-lg w-100 text-white">
                              Exit
                          </button>
                       </>):(<>
                        <a href="javascript:;" class="logoText">
                      <h3>Chapter <span>Quiz</span></h3>
                        </a>
                        <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i>You will now be presented with a question from this chapter.  Select Next when you are ready to answer this question.  You will have 3 minutes to complete the quiz.</strong>
                        <br></br>
                        <h2 class="fw-bold text-primary-s ">If you are not ready to answer the question.  Select Back to review the chapter again or Exit to leave the course.</h2>
                        {quizbutton?<span class="loader-test"></span>:<></>}
                        <div className="row">
                        <div class="col-lg-2 mt-4">
                          
                          <button onClick={handleExit} class="btn btn-secondary-s mb-3 btn-md  w-90 text-white">
                            <i class="fa fa-arrow-left fs-12"></i> Exit
                          </button>
                          </div>
                        <div class="col-lg-10 col-md-12">
                            <div class="d-flex justify-content-between mt-4 gap-4">
                              <button
                                class="btn btn-secondary-s text-white btn-md w-50 rounded-2"
                                onClick={cancelQuiz}
                              >
                                <i class="fa fa-angle-left me-1"></i> Back
                              </button>
                              <button
                                class="btn course-start btn-primary-s text-white btn-md w-50 rounded-2"
                                disabled={quizbutton}
                                onClick={handleQuiz}
                              >
                                NEXT <i class="fa fa-angle-right ms-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        
                        </>)}
                    </div>  
                  </div>):
                  (<>
                      <div class="title border-bottom pb-3">
                        <h5 class="h5 fw-bold">Chap {props.topic.position}: {props.topic.title}</h5>
                      </div>
                      {currentPage?<><div
                        className="pageContent contentDisplay mt-4"
                        dangerouslySetInnerHTML={{
                          __html: `<div class="pages-content"><div style=" display: flex; justify-content: space-between; align-items: center;">
                            <h2 style="margin: 0;">${currentPage.title || props.pages[0].title}</h2>
                            <span style="page-number margin: 0;">Page ${currentPageIndex+1} of ${props.pages.length}</span>
                          </div><br></br>` + currentPage.content+`</div>`,
                        }}
                      /></>:<></>}
                  
                      <div class="container mt-4">
                        <div class="row mt-3">
                          <div class="col-lg-2 mt-4">
                            {timerShow == false?(<button onClick={handleExit} class="btn btn-secondary-s mb-3 btn-md  w-90 text-white">
                                <i class="fa fa-arrow-left fs-12"></i> Exit
                              </button>):( <button  data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-secondary-s mb-3 btn-md  w-90 text-white">
                                <i class="fa fa-arrow-left fs-12"></i> Exit
                              </button>)
                            }
                         
                          </div>
                          <div class="col-lg-8 col-md-6">
                            <div class="d-flex justify-content-between mt-4 gap-4">
                              <button
                                class="btn btn-primary-s text-white btn-md w-50 rounded-2"
                                disabled={currentPageIndex === 0}
                                onClick={handlePrevious}
                              >
                                <i class="fa fa-angle-left me-1"></i> PREVIOUS
                              </button>
                             
                              <button
                                class="btn btn-primary-s text-white btn-md w-50 rounded-2"
                                disabled={
                                  currentPageIndex + 1 === props.pages.length && (
                                    lock || (
                                      sessionStorage.getItem('topicStatus') !== '1' ||
                                      (sessionStorage.getItem('topicStatus') === '1' && timerShow === true)
                                    )
                                  )
                                }
                                onClick={handleNext}
                                
                              >
                                NEXT <i class="fa fa-angle-right ms-1"></i>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-3">
                            <div className="timer ">
                              {/* {timerShow == false &&(
                                <CountdownCircleTimer
                                isPlaying
                                isGrowing={true}
                                size={90}
                                colors="#0BA8CF"
                              >
                                {({ remainingTime }) => {
                                    const minutes = Math.floor(totalTime / 60);
                                    const seconds = totalTime % 60;
                                    const formattedMinutes = String(minutes).padStart(2, '0');
                                    const formattedSeconds = String(seconds).padStart(2, '0');
                                    return `${formattedMinutes}:${formattedSeconds}`;
                                }}
                              </CountdownCircleTimer>
                              )} */}
                              {timerShow && backremainingTime !== null && (
                                  <CountdownCircleTimer
                                  isPlaying
                                  duration={totalTime}
                                  isGrowing={true}
                                  initialRemainingTime={backremainingTime}
                                  onUpdate={(remainingTime) => {
                                    currentCounterTimeRef.current = remainingTime;
                                    if (remainingTime === 0) {
                                      setTimerShow(false);
                                      updateRemainingTime();
                                    }
                                  }}
                                  size={100}
                                  colors="#0BA8CF"
                                >
                                  {({ remainingTime }) => {
                                      const elapsedTime = totalTime - remainingTime; // Calculate elapsed time
                                      const minutes = Math.floor((elapsedTime % 3600) / 60);
                                      const seconds = elapsedTime % 60;
                                      const formattedMinutes = String(minutes).padStart(2, "0");
                                      const formattedSeconds = String(seconds).padStart(2, "0");
                                      return `${formattedMinutes}:${formattedSeconds}`;
                                  }}
                                </CountdownCircleTimer>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                  </>)}
              </div>
              </>
          ):(
            <div className="d-flex justify-content-center">
              {displayback?(
                 <div className="entry-screen">
                 <a href="javascript:;" class="logoText">
                   <h3>Welcome Back to the <span>FTLSAE</span> Course</h3>
                 </a>
                 <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Choose
                 Wisely</strong>
                 <br></br>
                 <h2 class="fw-bold text-primary-s ">To resume the course click Resume</h2>
                <div className="action-buttons ">
                      <button name="btn" onClick={handlestart} className="btn course-start btn-secondary-s px-4 py-3 my-4 w-100 text-white" >Resume Where I Left Off </button>
                      <button name="btn" onClick={handleButtonClick} className="btn course-start btn-primary-s px-4 py-3 my-4 w-100 text-white" > Review Completed Chapter </button>
                      {isTextVisible && (
                    <h2 className="fw-bold text-primary-s">
                      Use the left side menu to select any completed chapter to review.
                    </h2>
                  )}
                 </div>
                 </div>  
              ):JSON.parse(sessionStorage.getItem('user')).course_status === '2'?( 
                <>
                {/* <div className="entry-screen">
                  <a href="javascript:;" class="logoText">
                    <h3>Course <span>Result</span> </h3>
                  </a>
                  <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Choose
                  Wisely</strong>
                  <br></br>
                  <div className="action-buttons ">
                  <button name="btn" onClick={handlecompleteread} class="btn course-start btn-primary-s px-4 py-3 my-4 w-100  text-white">Review Completed Chapters </button>
                  <button name="btn" onClick={handlestart} class="btn course-start btn-secondary-s px-4 py-3 my-4 w-100 text-white">Retake</button>

                  </div>
                </div>   */}
                <div className="entry-screen">
                 <a href="javascript:;" class="logoText">
                   <h3>Welcome Back to the <span>FTLSAE</span> Course</h3>
                 </a>
                 <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Choose
                 Wisely</strong>
                 <br></br>
                 {/* <h2 class="fw-bold text-primary-s ">To resume the course click Resume</h2> */}
                 {/* <h2 class="fw-bold text-primary-s ">Use the left side menu to select any completed chapter to review.</h2> */}
                 <div className="action-buttons ">
                  <button name="btn" className="btn course-start btn-primary-s px-4 py-3 my-4 w-100 text-white" onClick={handleButtonClick} >
                    Review Completed Chapter
                  </button>
                  {isTextVisible && (
                    <h2 className="fw-bold text-primary-s">
                      Use the left side menu to select any completed chapter to review.
                    </h2>
                  )}
                      {/* <button name="btn" onClick={readchapter} className="btn course-start btn-primary-s px-4 py-3 my-4 w-100 text-white" > Review Completed Chapter </button> */}
                      <button name="btn" onClick={handlestart} className="btn course-start btn-secondary-s px-4 py-3 my-4 w-100 text-white" >Resume Where I Left Off </button>
                 </div>
                 </div>  
                </>
                ):(<div className="entry-screen">
                  <a href="javascript:;" class="logoText">
                    <h3>Welcome to the  <span>FTLSAE</span> Course</h3>
                  </a>
                  <strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Choose
                  Wisely</strong>
                  <br></br>
                  <h2 class="fw-bold text-primary-s ">Click Start when ready to begin.</h2>
                  <button name="btn" onClick={handlestart} class="btn course-start btn-primary-s px-4 py-3 my-4 w-100 text-white">Start</button>
                  </div>  )
              }      
            </div>
              )
            }
      </>
  );

}
export default Content;
