import React from "react";
import { useEffect, useState } from "react";
import { getTestResults } from "../../services/api";
import { useNavigate } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { getPaymentIntent } from "../../services/api";
import { downloadPdf } from "../../services/api";
import Retakecheckout from './Retakecheckout';
import Modal from "react-modal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const CourseResultCard = (props)=>{
	const [tests , setTests] = useState([]);
	const [clientSecret, setClientSecret] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isloader , setIsLoader] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate= useNavigate();
	useEffect(()=>{	
		getResults();
	},[]);

	const generatePdf =async()=>{
		var user_id = sessionStorage.getItem("user_id");
		var token  = sessionStorage.getItem("token");
		const pdfBlob = await downloadPdf(token, user_id);
		const blob = new Blob([pdfBlob.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Completion_Certificate.pdf'; 
        link.click();
	}

	const handleExam=()=>{
		sessionStorage.setItem("examAllow",true);
		navigate('/course/take-exam')
	}
	const getResults=async()=>{
		const token = sessionStorage.getItem("token");
		const userId = sessionStorage.getItem("user_id");
		const response = await getTestResults(token, userId);
		setTests(response.data.results);
	}

	const readcourse =()=>{
		props.setStartCourse(false);
		navigate('/course/home');
	}

	const handleNext = async (e) => {
		setIsLoading(true);  // Set loading to true before the request
		e.preventDefault();
		const response = await getPaymentIntent();
		setClientSecret(response.data);
		setIsModalOpen(true);
		setIsLoading(false);  // Set loading to true before the request
	  };
	return (
		<>
			<div className="container mt-4">
				<div className="row mt-4">
					<div className="col-12 d-flex mt-4 justify-content-center">
						<h1 className="course-result-heading">Course Results</h1>
					</div>
					<div className="col-md-6 d-flex mt-4 justify-content-center ">
						<h1 className="course-status-text">Course Status: </h1>
					<span className={`badge ${JSON.parse(sessionStorage.getItem("user")).course_status === '2'?'bg-success':'bg-primary'} alerting`}>{JSON.parse(sessionStorage.getItem("user")).course_status === '2'?'Completed':'Inprogress'}</span>
					</div>
					<div className="col-md-6 d-flex mt-4 justify-content-center ">
						<h1 className="course-status-text ">Test Status: </h1>
						<span className={`badge ${JSON.parse(sessionStorage.getItem("user")).test_status === '1'?'bg-success':'bg-primary'} alerting`}>{JSON.parse(sessionStorage.getItem("user")).test_status === '1'?'Completed':'Incomplete'}</span>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 table-result-card mt-4">
					<table class="table mt-4">
						<thead>
							<tr>
							<th scope="col">#</th>
							<th scope="col">Test</th>
							<th scope="col">Date Taken</th>
							<th scope="col">Result</th>
							</tr>
						</thead>
						<tbody>
							{tests.length>0?<>
								{tests.map((test,index)=>
								<tr key={index}>
								<td scope="row">{index+1}</td>
								<td>{test.test_type === '0' ? 'Original':'Retake'}</td>
								<td>{test.date_taken}</td>
								<td><span className={`badge ${test.result === '0' ? 'bg-danger':'bg-success'}`}>{test.result === '0' ? 'Fail':'Passed'}</span></td>
								</tr>
							)}</>:<span class="loading-text-spinner">Loading .....</span>}
						</tbody>
					</table>
					
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 mt-4">
						<h1 className="course-result-heading">Next Step</h1>
					</div>
					<div className="col-12 mt-4">
						{JSON.parse(sessionStorage.getItem('user')).retake_status === '1'?	<button className="call-to-action-btn" onClick={handleExam}>Take Free Retake</button>:<></>}
						{JSON.parse(sessionStorage.getItem('user')).retake_status === '2' && JSON.parse(sessionStorage.getItem('user')).retake_permission === '1' ?	<button onClick={handleExam} className="call-to-action-btn">Use Purchased Retake</button>:<></>}
						{JSON.parse(sessionStorage.getItem('user')).retake_status === '2' && JSON.parse(sessionStorage.getItem('user')).retake_permission === '0' ?	<button onClick={handleNext} className="call-to-action-btn" style={{minWidth: '185px'}}>{isLoading ? (
							<span>Loading...</span>  // Show loading text or a spinner
						) : (
							'Purchase Retake'  // Show the button text when not loading
						)}</button>:<></>}
						{JSON.parse(sessionStorage.getItem('user')).test_status === '1'?<button onClick={generatePdf} className="download-pdf-btn">Download Certificate</button>:<></>}
						<button onClick={readcourse} className="download-pdf-btn ms-3" >Review Completed Chapters</button>
					</div>
				</div>
				<div className="row  mt-4">
				
			</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				className="modal-class"
				overlayClassName="overlay-class"
				ariaHideApp={false}
			>
				<div className="modal-header">
				<h2>Complete Your Payment</h2>
				<button onClick={() => setIsModalOpen(false)} className="close-btn">
					&times;
				</button>
				</div>
				<div className="modal-body">
				{clientSecret ? (
					<Elements stripe={stripePromise} options={{ clientSecret }}>
					<Retakecheckout
						clientSecret={clientSecret}
						setIsLoader={setIsLoader}
					/>
					</Elements>
				) : (
					<p>Loading payment details...</p>
				)}
				</div>
			</Modal>
		</>
	);
}
export default CourseResultCard;